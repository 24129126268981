import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import settings from '../../settings';
import 'react-lazy-load-image-component/src/effects/blur.css';
const bmwPerformanceLogoNew = `${settings.IMAGES_BASE_URL}/v2/images/BMW-pdc-logo-new.svg`;
const Menus = {
  main: "mainMenu",
  classes: "classesMenu",
  mTrackSeries: "mTrackMenu",
  indianapolis: "IndianapolisMenu",
  mSchool:"mSchool"
};

const MobileNav = props => {
  useEffect(() => {
    if (!menu && props.isOpen) {
      setMenu(Menus.main);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if(props.isOpen){
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [props.isOpen]);

  const [menu, setMenu] = useState(props.isOpen ? Menus.main : null);

  const onSetMenu = value => () => {
    setMenu(value);
  };

  return (
    <>
      <TransitionGroup>
        {menu === Menus.main && (
          <CSSTransition
            timeout={300}
            classNames="slide"
            key={Menus.main}
          >
            <div className="mobile-header">

            <div className="bmw-logo mobile-logo-top">
              <Link to="/" aria-label="Home">
                <LazyLoadImage
                    effect="blur"
                    src={bmwPerformanceLogoNew}
                    alt="BMW Performance Driving School"
                    className="bmw-logo mobile-logo-top"
                />
              </Link>
            </div>

              <div
                className="mobile-header__item"
                onClick={onSetMenu(Menus.classes)}
              >
                Classes
              </div>
              <div
                className="mobile-header__item"
                onClick={onSetMenu(Menus.mTrackSeries)}
              >
                M Track Series
              </div>
              <Link className="mobile-header__item" to="/experiences">
                Experiences
              </Link>
              <Link className="mobile-header__item" to="/groupevents">
                Group Events
              </Link>
              <Link className="mobile-header__item" to="/delivery">
                Delivery
              </Link>
              <Link className="mobile-header__item" to="/locations">
                Locations
              </Link>
              <a
                className="mobile-header__item mobile-header__item_blue"
                href="https://pds.eventsbmw.com/"
                target="_blank"
              >
                Book Now
              </a>
            </div>
          </CSSTransition>
        )}
        {menu === Menus.classes && (
          <CSSTransition timeout={300} classNames="slide" key={Menus.classes}>
            <div className="mobile-header">
              <Link className="mobile-header__item" to="/teenschool">
                Teen School
              </Link>
              <Link className="mobile-header__item" to="/driverschool">
                Driver's School
              </Link>
              <div className="mobile-header__item" onClick={onSetMenu(Menus.mSchool)}>
                M School
              </div>
              <div
                className="mobile-header__item"
                onClick={onSetMenu(Menus.main)}
              >
                &#60; Back
              </div>
            </div>
          </CSSTransition>
        )}

        {menu === Menus.mTrackSeries && (
          <CSSTransition
            timeout={300}
            classNames="slide"
            key={Menus.mTrackSeries}
          >
            <div className="mobile-header">
              <div
                className="mobile-header__item"
                onClick={onSetMenu(Menus.indianapolis)}
              >
                Indianapolis
              </div>
              <div
                className="mobile-header__item"
                onClick={onSetMenu(Menus.main)}
              >
                &#60; Back
              </div>
            </div>
          </CSSTransition>
        )}

        {menu === Menus.indianapolis && (
          <CSSTransition
            timeout={300}
            classNames="slide"
            key={Menus.indianapolis}
          >
            <div className="mobile-header">
              <Link className="mobile-header__item" to="/indy-fullday">
                Full-Day M Intensive
              </Link>
              <div
                className="mobile-header__item"
                onClick={onSetMenu(Menus.mTrackSeries)}
              >
                &#60; Back
              </div>
            </div>
          </CSSTransition>
        )}

        {menu === Menus.mSchool && (
          <CSSTransition
            timeout={300}
            classNames="slide"
            key={Menus.mSchool}
          >
            <div className="mobile-header">
              <Link className="mobile-header__item" to="/mschool/mdriver">
                M Driver's Package
              </Link>
              <Link className="mobile-header__item" to="/mschool/onedaymschool">
                One-Day M School
              </Link>
              <Link className="mobile-header__item" to="/mschool/twodaymschool">
                Two-Day M School
              </Link>
              <Link className="mobile-header__item" to="/mschool/advancedmschool">
                Advanced M School
              </Link>
              <Link className="mobile-header__item" to="/mschool/m4gt4">
                M4 GT4 School
              </Link>
              <Link className="mobile-header__item" to="/mschool/racelicenseschool">
                Race License School
              </Link>
              <div
                className="mobile-header__item"
                onClick={onSetMenu(Menus.classes)}
              >
                &#60; Back
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

MobileNav.defaultProps = {
  isOpen: false
};

MobileNav.propTypes = {
  isOpen: PropTypes.bool
};

export default MobileNav;
